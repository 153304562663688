import React, { useState } from "react";
import Menu from "../components/Menu";
import HeroUnit from "../components/HeroUnit";
import Intro from "../components/Intro";
import Projects from "../components/Projects";
import Contact from "../components/Contact";
import { Waypoint } from "react-waypoint";

const IndexPage = () => {
  const [currentMenuItem, setCurrentMenuItem] = useState("about");

  return (
    <>
      <Menu current={currentMenuItem} setCurrent={setCurrentMenuItem} />

      <Waypoint onEnter={() => setCurrentMenuItem("about")}>
        <HeroUnit />
      </Waypoint>

      <div className="max-w-md md:max-w-2xl lg:max-w-4xl mx-auto m-4 p-4 mb-28 pb-0">
        <Intro />
      </div>

      <div className="max-w-md md:max-w-2xl lg:max-w-5xl mx-auto p-4">
        <hr />
        <Waypoint onEnter={() => setCurrentMenuItem("projects")}>
          <Projects />
        </Waypoint>
        <hr className="hr-margin-top" />
      </div>

      <div className="max-w-md mx-auto p-4 m-20">
        <Waypoint onEnter={() => setCurrentMenuItem("contact")}>
          <Contact />
        </Waypoint>
      </div>
    </>
  );
};

export default IndexPage;
