import React, { memo } from "react"
import HeroBG from "../images/hero-bg-2.jpg"

const HeroUnit = (props) => {
    return (
        <div id="about" className="h-60 md:h-72 relative overflow-clip scroll-mt-14" ref={props.innerRef}>
            <div style={{ backgroundImage: `url(${HeroBG})` }} className="animate-zoom-slow absolute h-full w-full bg-cover bg-bottom md:bg-center"></div>
            <div className="p-4 rounded-lg max-w-md md:max-w-2xl lg:max-w-4xl mx-auto inset-8 md:inset-14 lg:inset-12 absolute backdrop-blur-md">
                <h1 className="text-3xl md:text-5xl lg:text-6xl text-orange-200 dark:text-orange-200 font-light tracking-wide">
                    Full-stack engineer with over<br /> <span className="text-orange-400 dark:text-orange-400 pb-4">two decades</span> of experience
                </h1>
                <h2 className="pt-3 md:text-lg lg:text-xl text-gray-300 dark:text-gray-300">
                    I enjoy making stunning solutions that enable intuitive user experiences.
                </h2>
            </div>
        </div>
    );
}

export default memo(HeroUnit);