import React, { useEffect, useState, memo } from 'react';

const Contact = ({ innerRef }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [siteKey, setSiteKey] = useState('');
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        // Get the site key from our API
        fetch(`/api/site-key`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(({ siteKey: resSiteKey }) => {
                // Capture the site key in our state
                setSiteKey(resSiteKey);

                // Initialize the recaptcha
                const script = document.createElement('script');
                script.src = `https://www.google.com/recaptcha/api.js?render=${resSiteKey}`;
                document.body.appendChild(script);
            });
    }, []);

    const clearForm = () => {
        setName('');
        setEmail('');
        setMessage('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Prevent multiple submissions
        if (processing) {
            return;
        }

        // Clear previous message and error
        resultMessage && setResultMessage('');
        error && setError('');

        // Validate the form
        if (!name || !email || !message) {
            setError('Please fill out all fields');
            return;
        }

        setProcessing(true);

        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(siteKey, { action: 'submit' }).then(function (token) {

                fetch('/api/form', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: name,
                        email: email,
                        message: message,
                        token: token
                    })
                }).then(response => response.json())
                    .then(data => {
                        setProcessing(false);

                        if (data.error) {
                            setError(data.error);
                        }

                        setResultMessage(data.message);
                        setSuccess(true);
                        clearForm();
                    });

            });
        });
    };

    const handleChange = (setFunc) => {
        return (e) => setFunc(e.target.value);
    };

    return <div ref={innerRef}>
        <h4 id="contact" className="text-4xl mt-28 text-gray-600 dark:text-gray-300 font-extralight tracking-wider scroll-mt-14">
            Want to get in touch?
        </h4>
        <p className="text-gray-500 dark:text-gray-400 mb-5">
            Fill out this short form to send me a message.
        </p>
        <form className="space-y-5 mt-2 mb-20" onSubmit={handleSubmit}>
            <div>
                <label className="block text-gray-500 dark:text-gray-400" htmlFor="name">Name</label>
                <input value={name} onChange={handleChange(setName)} type="text" name="name" id="name" className={`p-2 mt-1 rounded-md text-zinc-800 drop-shadow-lg focus:ring-slate-300 ${!name && error && "ring-2 ring-red-300 bg-red-100"}`} />
            </div>
            <div>
                <label className="block text-gray-500 dark:text-gray-400" htmlFor="email">Email Address</label>
                <input value={email} onChange={handleChange(setEmail)} type="email" name="email" id="email" className={`p-2 mt-1 rounded-md text-zinc-800 drop-shadow-lg ${!email && error && "ring-2 ring-red-300 bg-red-100"}`} />
            </div>
            <div>
                <label className="block text-gray-500 dark:text-gray-400" htmlFor="message">Your Message</label>
                <textarea value={message} onChange={handleChange(setMessage)} name="message" id="message" rows="4" className={`p-2 mt-1 rounded-md w-full text-zinc-800 drop-shadow-lg ${!message && error && "ring-2 ring-red-300 bg-red-100"}`}></textarea>
                <div className="text-gray-500 dark:text-gray-400 text-2xs" >
                    This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" className="hover:text-gray-700 hover:dark:text-gray-300">Privacy Policy</a> and <a href="https://policies.google.com/terms" className="hover:text-gray-700 hover:dark:text-gray-300">Terms of Service</a> apply.
                </div>
            </div>

            <div className="flex justify-end items-center gap-3">
                {error && <p className="text-red-500 dark:text-red-400">{error}</p>}
                {success && <p className="text-green-500 dark:text-green-400">{resultMessage}</p>}
                <button type="submit" value="Send" className="inline-flex items-center bg-orange-500 text-gray-100 p-2 pl-5 pr-5 rounded-lg">
                    {processing &&
                        <>
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Sending...
                        </>
                    }
                    {!processing && <>Send</>}
                </button>
            </div>
        </form>
    </div>
};

export default memo(Contact);