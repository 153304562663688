import React, { memo } from "react";
import { PL, AR, BFE, E3DPM } from "../images/projects/index";

const Projects = (props) => {
  const ProjectImage = (props) => {
    return (
      <div className={props.divClassName}>
        <img
          src={props.image}
          className={`object-cover object-top h-32 lg:h-full mx-auto ${props.imgClassName}`}
          alt="{props.alt}"
        />
      </div>
    );
  };

  const ProjectItem = (props) => {
    return (
      <div className="mt-20 text-center">
        <h2 className="text-5xl">{props.name}</h2>
        <h3 className="text-gray-500 dark:text-gray-400 m-3">
          {props.subheader}
        </h3>
        {props.children}
      </div>
    );
  };

  return (
    <div ref={props.innerRef}>
      <h1
        id="projects"
        className="text-4xl md:text-5xl text-center mt-28 text-gray-600 dark:text-gray-300 font-extralight tracking-wider scroll-mt-14"
      >
        Personal Projects
      </h1>

      <ProjectItem
        name="Elegoo 3D Printer Monitor"
        subheader="Provides remote status of Elegoo 3D Printers with Wi-Fi Support"
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 items-center">
          <a href="https://github.com/ssewell/Elegoo3DPrinterMonitor">
            <ProjectImage image={E3DPM} alt="Elegoo 3D Printer Monitor" />
          </a>
          <div className="text-left space-y-5 col-span-2">
            <p className="max-w-lg mx-auto text-gray-500 dark:text-gray-400">
              In 2023, Elegoo launched a new series of MLSA printers featuring
              native Wi-Fi support. Realizing the potential for remote
              monitoring, I conducted thorough research only to find that no
              existing solutions met this specific need. Motivated to fill this
              gap, I took the initiative to develop a tool myself, allowing for
              the remote monitoring of the printer status.
            </p>
            <p className="max-w-lg mx-auto text-gray-500 dark:text-gray-400">
              The project, showcasing my proactive approach to problem-solving
              and technical proficiency, is openly available on{" "}
              <a
                href="https://github.com/ssewell/Elegoo3DPrinterMonitor"
                className="external-link"
              >
                GitHub
              </a>{" "}
              for others to explore and potentially expand upon.
            </p>
            <p className="max-w-lg mx-auto">
              Primary technologies: Electron, React, TypeScript, Node.js
            </p>
          </div>
        </div>
      </ProjectItem>
      <hr className="hr-margin-top" />
      <h1
        id="projects"
        className="text-4xl md:text-5xl text-center mt-28 text-gray-600 dark:text-gray-300 font-extralight tracking-wider scroll-mt-14"
      >
        Professional Projects
      </h1>
      <p className="text-center m-3 dark:text-gray-400">
        Below are a few projects where I took the lead as the primary developer:
        <br />
      </p>

      <ProjectItem
        name="ProcessorLink"
        subheader="Commodity bank tracking and management"
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 items-center">
          <ProjectImage image={PL} alt="ProcessorLink" />
          <div className="text-left space-y-5 col-span-2">
            <p className="max-w-lg mx-auto text-gray-500 dark:text-gray-400">
              A trusted resource in the foodservice manufacturing sector,
              ProcessorLink has efficiently managed the tracking of commodity
              bank accounts, meticulously monitoring over $4.1 billion in
              commodity value to date, thereby standing as a vital tool for both
              manufacturers and their clientele.
            </p>
            <p className="max-w-lg mx-auto">
              Primary technologies: ASP.NET, HTML, CSS, JavaScript, SQL Server
            </p>
          </div>
        </div>
      </ProjectItem>

      <ProjectItem
        name="Advanced Reporting"
        subheader="Pane of Glass Business Intelligence Reporting"
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 items-center">
          <div className="text-left space-y-5 col-span-2">
            <p className="max-w-lg mx-auto text-gray-500 dark:text-gray-400">
              The Advanced Reporting tool offers an elevated level of business
              oversight through its graphically-centric design, facilitating a
              dynamic visualization of business performance. Users benefit from
              its intuitive interface, allowing for swift analysis of summary
              data, coupled with the capability to delve deeper into underlying
              details for a comprehensive understanding of the data landscape.
            </p>
            <p className="max-w-lg mx-auto">
              Primary technologies: ASP.NET, JavaScript, SQL Server
            </p>
          </div>
          <ProjectImage
            image={AR}
            alt="Advanced Reporting"
            divClassName="row-start-1 lg:row-start-auto"
          />
        </div>
      </ProjectItem>

      <ProjectItem
        name="BidForge Enterprise"
        subheader="Sales Opportunity Tracking"
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 items-center">
          <ProjectImage image={BFE} alt="BidForge Enterprise" />
          <div className="text-left space-y-5 col-span-2">
            <p className="max-w-lg mx-auto text-gray-500 dark:text-gray-400">
              BidForge Enterprise excels in opportunity tracking, fostering
              active engagement among all stakeholders. Upon identifying a sales
              opportunity, it utilizes customer attributes to ascertain the
              optimal price points for all products, streamlining the process
              and facilitating immediate price submissions.
            </p>
            <p className="max-w-lg mx-auto">
              Primary technologies: ASP.NET MVC, AngularJS, JavaScript, Entity
              Framework, SQL Server
            </p>
          </div>
        </div>
      </ProjectItem>
    </div>
  );
};

export default memo(Projects);
