import React, { memo } from "react"

const Menu = (props) => {
    const MenuElement = (props) => {
        return (<div className="flex-initial md:w-32">
            <a href={`#${props.link}`} onClick={() => props.setCurrent(props.link)}>
                <span className={`${props.current === props.link ? "border-orange-400 border-b-2" : ""}`}>
                    {props.name}
                </span>
            </a>
        </div>)
    };

    return (
        <div className="sticky top-0 z-50 flex p-4 bg-zinc-200 dark:bg-gray-700 space-x-10">
            <div className="flex-1">
                🚀 Shane Sewell
            </div>
            <MenuElement {...props} name="About" link="about" />
            <MenuElement {...props} name="Projects" link="projects" />
            <MenuElement {...props} name="Contact" link="contact" />
        </div>
    );
}

export default memo(Menu)