import React, { memo } from "react";

const Intro = () => {
  const CodeText = ({ children, className }) => {
    return <span className={`text-orange-500 dark:text-orange-200 ${className}`}> {children}</span>;
  };

  return (
    <div className="space-y-6">
      <p>
        <span className="text-2xl">Hi!👋🏻</span>
        I'm Shane Sewell, a full-stack engineer with a passion for software that enables users to do
        their job easily while staying out of the way. You'll usually find me working with{" "}
        <CodeText>ASP.NET</CodeText>, <CodeText>C#</CodeText>, <CodeText>HTML</CodeText>,{" "}
        <CodeText>CSS</CodeText>, <CodeText>Razor</CodeText>, <CodeText>AngularJS</CodeText>, and{" "}
        <CodeText>SQL Server</CodeText>, and I've been recently focusing on other technologies such
        as <CodeText>React</CodeText> and <CodeText>Next.js</CodeText> just to name a few.
      </p>
      <p>
        But <CodeText className="italic">code</CodeText> is just one part of the equation. From the
        first user interaction to the back-end infrastructure that hosts your solution, my diverse
        skill set can help you design, implement, deploy, and scale all pieces to the modern
        software-stack puzzle.
      </p>
      <p>
        In all of the projects listed below, in addition to design and coding, I've been the primary
        individual responsible for SCM selection, branching strategies, DevOps, InfoSec, choice of
        frameworks and libraries, hardware selection, networking, backup strategy, and providing
        guidance on best practices to employees.
      </p>
    </div>
  );
};

export default memo(Intro);
